import React, { useEffect, useState } from "react";
import { links } from "../routes";
import { NavLink, withRouter } from "react-router-dom";
import styled from "styled-components";
import { device, theme } from "../theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

const Header = styled.header`
  z-index: 1100;
  height: ${(props) => (props.open ? "100vh" : "5rem")};
  width: ${(props) => (props.open ? "100vw" : "100%")};
  position: fixed;
  background-color: ${theme.colors.dark};
  text-transform: uppercase;
`;

const HeaderIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 1rem;
  left: 1.6rem;
  color: ${theme.colors.primary};
  font-size: 3.2rem;
  cursor: pointer;

  @media all and ${device.tablet} {
    display: none;
  }
`;

const NavLinksContainer = styled.div`
  display: ${(props) => (props.isOpen ? "flex" : "none")};
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  font-size: 2.2rem;
  flex-direction: column;
  align-items: center;

  @media all and ${device.tablet} {
    all: unset;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
  }
`;

const StyledNavLink = styled(NavLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 2rem 0;

  &:hover {
    color: ${theme.colors.dark};
    text-decoration: none;
  }

  @media all and ${device.tablet} {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    padding: 0 1rem;
    margin: 0 0.5rem;
  }
`;

const Link = styled.span`
  position: relative;
  color: ${theme.colors.primary};
  font-weight: bold;

  &:after {
    display: block;
    content: "";
    position: absolute;
    bottom: -0.5rem;
    left: 0;
    right: 0;
    height: 2px;
    border-radius: 1px;
    background-color: ${theme.colors.primary};
    transform: ${(props) => (props.active ? "scaleX(1)" : "scaleX(0)")};
    transition: transform 250ms ease-in-out;
  }
  :hover:after {
    transform: scaleX(1);
  }
`;

const Navbar = ({ location }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(false);
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Header open={open}>
      <HeaderIcon
        icon={open ? faTimes : faBars}
        onClick={() => setOpen((prev) => !prev)}
      />
      <NavLinksContainer isOpen={open}>
        {links.map((link, index) => (
          <StyledNavLink key={index} exact to={link.path}>
            <Link active={link.activePaths.includes(location.pathname)}>
              {link.name}
            </Link>
          </StyledNavLink>
        ))}
      </NavLinksContainer>
    </Header>
  );
};

export default withRouter(Navbar);
