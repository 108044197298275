import React, { useState } from "react";
import styled from "styled-components";
import { device, theme } from "../../../theme";
import MyLightbox from "../../../components/Lightbox";

const Card = styled.div`
  border-radius: ${theme.border.radius};
  background-color: ${theme.colors.lightGray};
  position: relative;
  width: 100%;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-image: ${(props) => `url(${props.image})`};
  height: 20rem;
  cursor: ${(props) => (props.images?.length > 0 ? "pointer" : "normal")};

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${theme.colors.dark};
    opacity: 0.7;
  }
`;

const CardText = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${theme.colors.primary};
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  z-index: 10;
`;

const RealizationCard = ({ realization }) => {
  const { thumbnail_url, name, image_urls } = realization;
  const [isOpen, setIsOpen] = useState(false);
  const images = image_urls?.map((image) => image.image_url);
  return (
    <>
      <MyLightbox isOpen={isOpen} setIsOpen={setIsOpen} images={images} />
      <Card
        image={`${thumbnail_url}`}
        onClick={() => setIsOpen(true)}
        images={images}
      >
        <CardText>{name}</CardText>
      </Card>
    </>
  );
};

export default RealizationCard;
