import React from "react";
import Map from "./components/Map";

import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { device, theme } from "../../theme";
import {
  faCalendarDay,
  faEnvelope,
  faInfoCircle,
  faMapMarkerAlt,
  faMobileAlt,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";

const InfoContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 1.8rem;
  align-items: center;
  margin-bottom: 3.2rem;

  @media all and ${device.tablet} {
    align-items: flex-start;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 3.2rem;
  align-items: center;

  @media all and ${device.tablet} {
    width: 50%;
    margin-bottom: 0;
  }
`;

const ColumnHeader = styled.span`
  color: ${theme.colors.primary};
  text-align: center;
  font-weight: bold;
  margin-bottom: 1.6rem;
`;

const TextRow = styled.a`
  all: unset;
  color: inherit;
  display: flex;
  align-items: center;
  margin-bottom: 1.6rem;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: ${theme.colors.primary};
    text-decoration-color: ${theme.colors.primary};
    transition: all 250ms ease-out;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  width: 2.2rem !important;
  height: 2.2rem !important;
  color: ${theme.colors.primary};
  margin-right: 1.6rem;
`;

const HoursTextRow = styled.p`
  display: flex;
  align-items: center;
`;

const Day = styled.span`
  display: inline-block;
  width: 15rem;
  color: ${theme.colors.primary};
  font-weight: bold;
`;

const HoursText = styled.span``;

const Contact = () => {
  return (
    <>
      <InfoContainer>
        <Column>
          <ColumnHeader>
            <Icon icon={faInfoCircle} />
            Informacje:
          </ColumnHeader>

          <div>
            <TextRow href={"tel:601480848"}>
              <Icon icon={faMobileAlt} /> 601-480-848
            </TextRow>
            <TextRow href={"tel:343680530"}>
              <Icon icon={faPhoneAlt} /> (34) 368-05-30
            </TextRow>
            <TextRow
              target={"_blank"}
              href={
                "https://www.google.com/maps/place/AUDIO+SYSTEM/@50.819021,19.063359,15z/data=!4m5!3m4!1s0x0:0xdeabcf37da093f46!8m2!3d50.8190205!4d19.0633589?hl=pl-PL"
              }
            >
              <Icon icon={faMapMarkerAlt} /> ul. Poznańska 53 <br /> 42-200
              Częstochowa
            </TextRow>
            <TextRow href={"mailto:audio-system@wp.pl"}>
              <Icon icon={faEnvelope} /> audio-system@wp.pl
            </TextRow>
          </div>
        </Column>
        <Column>
          <ColumnHeader>
            <Icon icon={faCalendarDay} />
            Godziny otwarcia:
          </ColumnHeader>

          <div>
            <HoursTextRow>
              <Day> poniedziałek</Day>
              <HoursText>10:00–18:00</HoursText>
            </HoursTextRow>

            <HoursTextRow>
              <Day> wtorek</Day>
              <HoursText>10:00–18:00</HoursText>
            </HoursTextRow>

            <HoursTextRow>
              <Day> środa</Day>
              <HoursText>10:00–18:00</HoursText>
            </HoursTextRow>

            <HoursTextRow>
              <Day> czwartek</Day>
              <HoursText>10:00–18:00</HoursText>
            </HoursTextRow>

            <HoursTextRow>
              <Day> piątek</Day>
              <HoursText>10:00–18:00</HoursText>
            </HoursTextRow>

            <HoursTextRow>
              <Day> sobota</Day>
              <HoursText>10:00–14:00</HoursText>
            </HoursTextRow>

            <HoursTextRow>
              <Day> niedziela</Day>
              <HoursText>Zamknięte</HoursText>
            </HoursTextRow>
          </div>
        </Column>
      </InfoContainer>
    </>
  );
};

export default Contact;
