import React, { useContext } from "react";
import { TextsContext, TextsKeysConstants } from "../../hooks/useTexts";
import TextCard from "./components/TextCard";
import { plPaths } from "../../routes";
import Image from "../../components/Image";
import { WidthContainer } from "../../App";

const MainPage = () => {
  const texts = useContext(TextsContext).texts || [];
  const aboutCompanyText = texts.find(
    (text) => text.key === TextsKeysConstants.ABOUT_COMPANY
  );
  const offerText = texts.find((text) => text.key === TextsKeysConstants.OFFER);
  const serviceText = texts.find(
    (text) => text.key === TextsKeysConstants.SERVICE
  );

  return (
    <div>
      <Image imgKey={"mainPage"} />
      <WidthContainer>
        {aboutCompanyText && <TextCard {...aboutCompanyText} />}
        {offerText && <TextCard {...offerText} to={plPaths.shop} />}
        {serviceText && <TextCard {...serviceText} to={plPaths.service} />}
      </WidthContainer>
    </div>
  );
};

export default MainPage;
