import { useEffect, useState } from "react";
import { enPaths, plPaths } from "../routes";

export const locations = {
  map: [plPaths.contact, enPaths.contact],
  shop: [plPaths.shop, enPaths.shop],
  image: [plPaths.service, enPaths.service, "/"],
};

export function useIsLocation(location, locations) {
  const [isLocation, setIsLocation] = useState(false);

  useEffect(() => {
    setIsLocation(locations.includes(location.pathname));
  }, [location, locations]);

  return isLocation;
}
