import { encodeURIString } from "../../helpers/encodeURIString";
import axios from "axios";
import { getSearchParams, searchParamsConstants } from "./filters";

export const getCategories = () => {
  const prepareCategories = (categories) => {
    return categories.map((category) => ({
      ...category,
      pathName: encodeURIString(category.name),
    }));
  };

  let url = `${process.env.REACT_APP_API_URL}/categories?_sort=name:ASC`;
  return axios
    .get(url)
    .then((response) => {
      return prepareCategories(response.data);
    })
    .catch((err) => console.log(err));
};

export const getProducts = (filters = {}) => {
  const searchParams = getSearchParams(filters, [
    searchParamsConstants.PAGE,
    searchParamsConstants.CATEGORY,
    searchParamsConstants.LIMIT,
  ]);

  const url = `${process.env.REACT_APP_API_URL}/products?_sort=price:DESC${
    searchParams ? `&${searchParams}` : ""
  }`;
  return axios
    .get(url)
    .then((response) => {
      return response.data;
    })
    .catch((err) => console.log(err));
};

export const getProductsCount = (filters = {}) => {
  const searchParams = getSearchParams(filters, [
    searchParamsConstants.CATEGORY,
  ]);

  const url = `${process.env.REACT_APP_API_URL}/products/count${
    searchParams ? `?${searchParams}` : ""
  }`;
  return axios
    .get(url)
    .then((response) => {
      return response.data;
    })
    .catch((err) => console.log(err));
};
