import React from "react";
import Pagination from "./Pagination";
import styled from "styled-components";
import { device, theme } from "../../../theme";
import NoPicture from "../../../assets/Images/no-picture.svg";

const ActiveCategoryName = styled.h2`
  color: ${theme.colors.dark};
  margin-bottom: 3.2rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
  justify-content: center;

  @media all and ${device.laptop} {
    justify-content: flex-start;
  }
`;

const List = styled.div`
  display: grid;
  grid-gap: 1.6rem;
  justify-items: center;
  padding-bottom: 3.2rem;
  grid-template-columns: repeat(2, minmax(0, 1fr));

  @media all and ${device.tabletS} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media all and ${device.tablet} {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  @media all and ${device.laptop} {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
`;

const ProductLink = styled.a`
  all: unset;
  width: 100%;
  &:hover {
    all: unset;
    width: 100%;
  }
`;

const Product = styled.div`
  cursor: pointer;
  background-color: ${theme.colors.dark};
  border-radius: ${theme.border.radius};
  max-width: 20rem;
  height: 100%;
  padding: 0.8rem;
  display: flex;
  flex-direction: column;

  &:hover {
    background-color: ${theme.colors.primary};
    transition: all 250ms ease-out;
  }

  @media all and ${device.laptop} {
    max-width: 30rem;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  background-color: ${theme.colors.white};
  border-radius: ${theme.border.radius};
  position: relative;

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
`;

const ImageContainerHelper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: ${theme.border.radius};
`;

const ProductImage = styled.img`
  width: 100%;
`;

const NoWrap = styled.span`
  white-space: nowrap;
`;

const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  padding-top: 0.8rem;
  height: 100%;
`;

const ProductName = styled.span`
  color: ${theme.colors.white};
  font-weight: bold;
  text-transform: uppercase;
`;

const ProductBrand = styled.span`
  margin-top: -1.4rem;
  font-size: 1rem;
  color: ${theme.colors.gray};
`;

const ProductPrice = styled.span`
  color: ${theme.colors.white};
  font-size: 2rem;
  display: block;
  font-weight: bold;
  text-align: right;
  margin-top: auto;
`;

const ProductList = (props) => {
  const {
    products,
    pageCount,
    activePage,
    handlePageClick,
    activeCategory,
    productsCount,
  } = props;
  const showPagination = products && pageCount > 1;

  const getProductsCountText = (productsCount) => {
    switch (productsCount % 10) {
      case 2:
      case 3:
      case 4:
        return ` (${productsCount} wyniki)`;
      default:
        return ` (${productsCount} wyników)`;
    }
  };

  console.log(products);

  return (
    <>
      <ActiveCategoryName>
        <NoWrap>
          {activeCategory ? activeCategory.name : "Wszystkie produkty"}
        </NoWrap>
        <NoWrap> {getProductsCountText(productsCount)}</NoWrap>
      </ActiveCategoryName>
      <List>
        {products &&
          products.map((product) => (
            <ProductLink
              key={`link-${product.link}`}
              href={product.link}
              target={"blank"}
            >
              <Product key={`product-${product.id}`}>
                <ImageContainer>
                  <ImageContainerHelper>
                    <ProductImage
                      src={product.image_url ? product.image_url : NoPicture}
                    />
                  </ImageContainerHelper>
                </ImageContainer>

                <ProductInfo>
                  <ProductName>{product.name}</ProductName>
                  {product.brand && (
                    <ProductBrand>{product.brand.name}</ProductBrand>
                  )}
                  <ProductPrice>
                    {product.price.toFixed(2).replace(".", ",")} zł
                  </ProductPrice>
                </ProductInfo>
              </Product>
            </ProductLink>
          ))}
      </List>
      {showPagination && (
        <Pagination
          pageCount={pageCount}
          handlePageClick={handlePageClick}
          activePage={activePage}
        />
      )}
    </>
  );
};

export default ProductList;
