export const theme = {
  colors: {
    primary: "#df7623",
    mediumGray: "#CCCCCC",
    lightGray: "#F0F0F0",
    gray: "#F0F0F0",
    darkGray: "#A3A3A3",
    dark: "#313131",
    lightDark: "#646464",
    white: "#ffffff",
    red: "#FF3B31",
    green: "#34C758",
    blue: "#1977F3",
    black: "#000000",
    transparent: "transparent",
  },
  header: {
    heightPxVal: 64,
    heightXSPxVal: 56,
  },
  footer: {
    heightPxVal: 64,
  },
  border: {
    radius: "4px",
  },
  devices: {
    mobileS: "320px",
    mobileM: "375px",
    mobileL: "425px",
    tabletS: "550px",
    tablet: "768px",
    laptop: "1024px",
    laptopL: "1440px",
    desktop: "2560px",
  },
};

export const device = {
  mobileS: `(min-width: ${theme.devices.mobileS})`,
  mobileM: `(min-width: ${theme.devices.mobileM})`,
  mobileL: `(min-width: ${theme.devices.mobileL})`,
  tabletS: `(min-width: ${theme.devices.tabletS})`,
  tablet: `(min-width: ${theme.devices.tablet})`,
  laptop: `(min-width: ${theme.devices.laptop})`,
  laptopL: `(min-width: ${theme.devices.laptopL})`,
  desktop: `(min-width: ${theme.devices.desktop})`,
  desktopL: `(min-width: ${theme.devices.desktop})`,
};
