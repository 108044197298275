import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { plPaths } from "../../../routes";
import styled from "styled-components";
import { device, theme } from "../../../theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faTimes } from "@fortawesome/free-solid-svg-icons";

const AsideContainer = styled.div`
  background-color: ${theme.colors.dark};
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: ${(props) => (props.isOpen ? "1200" : "1000")};
  padding: ${(props) => (props.isOpen ? "6.4rem" : "1.6rem")} 1.6rem 1.6rem
    1.6rem;
  cursor: ${(props) => (props.isOpen ? "auto" : "pointer")};
  overflow-y: auto;

  @media all and ${device.laptop} {
    all: unset;
    display: flex;
    background-color: ${theme.colors.dark};
    position: absolute;
    left: 1.6rem;
    top: 8.2rem;
    padding: 1.6rem;
    border-radius: ${theme.border.radius};
    overflow-y: auto;
  }
`;

const AsideTitle = styled.h2`
  font-weight: bold;
  color: ${theme.colors.primary};
`;

const AsideLinksContainer = styled.div`
  display: ${(props) => (props.isOpen ? "flex" : "none")};
  flex-direction: column;

  @media all and ${device.laptop} {
    display: flex;
  }
`;

const AsideLinkCategory = styled(Link)`
  position: relative !important;
  all: unset;
  text-align: left;
  width: 100%;
  text-decoration: none;
  display: inline;
  color: ${(props) =>
    props.isActive ? theme.colors.primary : theme.colors.lightGray};
  padding: 0.8rem 0 0.8rem 0.8rem;
  cursor: pointer;

  &:hover {
    color: ${theme.colors.primary};
    transition: all 250ms ease-out;
    text-decoration: none;

    &:before {
      background-color: ${theme.colors.primary};
      transition: all 250ms ease-out;
    }
  }

  &:before {
    position: absolute;
    content: "";
    left: 0;
    width: 2px;
    top: 0.8rem;
    bottom: 0.8rem;
    border-radius: 1px;
    background-color: ${(props) =>
      props.isActive ? theme.colors.primary : theme.colors.lightGray};
  }
`;

const ExpandIcon = styled(FontAwesomeIcon)`
  color: ${theme.colors.primary};
  font-size: 3.2rem;
  margin-top: 50vh;
  transform: translateY(-50%);
  position: fixed;

  @media all and ${device.laptop} {
    display: none;
  }
`;

const TransparentExpandIcon = styled(ExpandIcon)`
  position: relative;
  color: transparent;
`;

const CloseIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 1rem;
  left: 1.6rem;
  color: ${theme.colors.primary};
  font-size: 3.2rem;
  cursor: pointer;
`;

const Cover = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${theme.colors.dark};
  opacity: 0.7;
  z-index: 1150;
`;

const Aside = (props) => {
  const { categories, activeCategory } = props;
  const [isOpen, setIsOpen] = useState(false);

  const handleAsideLinkClick = (e) => {
    e.stopPropagation();
    setIsOpen(false);
  };

  return (
    <>
      {isOpen && <Cover onClick={() => setIsOpen(false)} />}
      <AsideContainer isOpen={isOpen} onClick={() => setIsOpen(true)}>
        {!isOpen && (
          <>
            <ExpandIcon icon={faAngleRight} />
            <TransparentExpandIcon icon={faAngleRight} />
          </>
        )}
        {isOpen && (
          <CloseIcon
            icon={faTimes}
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen(false);
            }}
          />
        )}
        <AsideLinksContainer isOpen={isOpen}>
          <AsideTitle>Kategorie</AsideTitle>
          <AsideLinkCategory
            isActive={!activeCategory}
            to={`${plPaths.shop}`}
            onClick={handleAsideLinkClick}
          >
            Wszystkie produkty
          </AsideLinkCategory>
          {categories &&
            categories.map((category, index) => {
              return (
                <AsideLinkCategory
                  isActive={activeCategory && activeCategory.id === category.id}
                  key={`category-${index}`}
                  to={`${plPaths.shop}?category=${category.pathName}`}
                  onClick={handleAsideLinkClick}
                >
                  {category.name}
                </AsideLinkCategory>
              );
            })}
        </AsideLinksContainer>
      </AsideContainer>
    </>
  );
};

export default withRouter(Aside);
