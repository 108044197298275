import { history } from "../../helpers/history";

export const searchParamsConstants = {
  CATEGORY: "category",
  PAGE: "page",
  LIMIT: "limit",
};

export const getFilters = (location, categories) => {
  const defaultPage = 1;
  const defaultPageLimit = 15;

  let filters = {};
  let newSearch = "";
  const params = new URLSearchParams(location.search);
  if (params.get("category")) {
    filters.category = categories.find(
      (category) => category.pathName === params.get("category")
    );
  } else {
    filters.category = null;
  }
  if (params.get("page")) {
    filters.page = params.get("page");
  } else {
    newSearch += `${
      newSearch ? `&page=${defaultPage}` : `page=${defaultPage}`
    }`;
  }

  if (params.get("limit")) {
    filters.pageLimit = params.get("limit");
  } else {
    newSearch += `${
      newSearch ? `&limit=${defaultPageLimit}` : `limit=${defaultPageLimit}`
    }`;
  }

  if (newSearch) {
    history.push({
      pathname: location.pathname,
      search:
        location.search + `${location.search ? `&${newSearch}` : newSearch}`,
    });
  }

  return filters;
};

export const getSearchParams = (filters, params) => {
  let paramsData = {};
  if (filters.category && params.includes(searchParamsConstants.CATEGORY)) {
    paramsData["category.id"] = filters.category.id;
  }
  if (filters.pageLimit && params.includes(searchParamsConstants.LIMIT)) {
    paramsData._limit = filters.pageLimit;
  }
  if (filters.page && params.includes(searchParamsConstants.PAGE)) {
    paramsData._start = (filters.page - 1) * paramsData._limit;
  }

  return new URLSearchParams(paramsData).toString();
};
