import React from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { routes } from "./routes";
import Navbar from "./components/Navbar";
import "./App.scss";
import { TextsContext, useTexts } from "./hooks/useTexts";
import styled from "styled-components";
import { device, theme } from "./theme";
import Footer from "./components/Footer";
import { locations, useIsLocation } from "./hooks/useIsLocation";
import CookieConsent from "react-cookie-consent";

export const FlexCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MainContainer = styled.div`
  min-height: 100vh;
  position: relative;
  color: ${theme.colors.dark};
`;

export const WidthContainer = styled.div`
  max-width: ${theme.devices.tablet};
  margin: 0 auto;

  @media all and ${device.laptopL} {
    max-width: ${(props) => (props.width100 ? "100%" : theme.devices.laptop)};
  }

  @media all and ${device.desktop} {
    max-width: ${theme.devices.laptopL};
  }
`;

const Container = styled.div`
  max-width: ${(props) => (props.width100 ? "100%" : theme.devices.tablet)};
  margin: 0 auto;
  padding: 8.2rem 1.6rem 0 1.6rem;
  min-height: ${(props) =>
    props.showMap ? "calc(100vh - 356px - 48px)" : "calc(100vh - 56px - 48px)"};

  @media all and ${device.laptop} {
    padding-top: 8.2rem;
  }

  @media all and ${device.laptopL} {
    max-width: ${(props) => (props.width100 ? "100%" : theme.devices.laptop)};
  }

  @media all and ${device.desktop} {
    max-width: ${theme.devices.laptopL};
  }
`;

function App({ location }) {
  const texts = useTexts();
  const showMap = useIsLocation(location, locations.map);
  const isShop = useIsLocation(location, locations.shop);
  const isImage = useIsLocation(location, locations.image);

  return (
    <MainContainer>
      <CookieConsent
        location="bottom"
        buttonText="Akceptuj"
        cookieName="cookie_acceptation"
        style={{ background: theme.colors.dark, color: theme.colors.primary }}
        buttonStyle={{
          background: theme.colors.primary,
          color: theme.colors.dark,
          fontSize: "13px",
        }}
        expires={150}
      >
        Ta strona wykorzystuje pliki cookies.
      </CookieConsent>
      <TextsContext.Provider value={{ texts }}>
        <Navbar />
        <Container width100={isShop || isImage} showMap={showMap}>
          <Switch>
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                exact
                component={route.component}
              />
            ))}
            <Redirect from="*" to={"/"} />
          </Switch>
        </Container>
        <Footer />
      </TextsContext.Provider>
    </MainContainer>
  );
}

export default withRouter(App);
