import React, { useContext, useEffect, useState } from "react";
import { TextsContext, TextsKeysConstants } from "../../hooks/useTexts";
import axios from "axios";
import styled from "styled-components";
import { theme } from "../../theme";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { WidthContainer } from "../../App";
import Image from "../../components/Image";

const ServiceContainer = styled.div`
  border-radius: ${theme.border.radius};
  background-color: ${theme.colors.lightGray};
  padding: 1.6rem;
  margin-bottom: 3.2rem;
`;

const ServiceList = styled.ul`
  all: unset;
`;

const ListItem = styled.li`
  list-style: none;
  padding: 0.8rem 0;
`;

const ListItemIcon = styled(FontAwesomeIcon)`
  color: ${theme.colors.primary};
  margin-right: 0.8rem;
`;

const Service = () => {
  const texts = useContext(TextsContext).texts || [];
  const serviceText = texts.find(
    (text) => text.key === TextsKeysConstants.SERVICE
  );
  const [services, setServices] = useState();

  useEffect(() => {
    let url = `${process.env.REACT_APP_API_URL}/services`;
    axios
      .get(url)
      .then((response) => {
        setServices(response.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <Image imgKey={"service"} />
      <WidthContainer>
        <ServiceContainer>
          {serviceText && <p>{serviceText.content}</p>}
          {services && (
            <ServiceList>
              {services.map((service, index) => (
                <ListItem key={`service-${index}`}>
                  <ListItemIcon icon={faCaretRight} />
                  {service.name}
                </ListItem>
              ))}
            </ServiceList>
          )}
        </ServiceContainer>
      </WidthContainer>
    </>
  );
};

export default Service;
