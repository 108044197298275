import React from "react";
import styled from "styled-components";
import { theme } from "../../../theme";
import { history } from "../../../helpers/history";
import { FlexCenter } from "../../../App";

const CardContainer = styled.div`
  background-color: ${theme.colors.lightGray};
  border-radius: ${theme.border.radius};
  padding: 1.6rem;
  margin-bottom: 3.2rem;
`;

const CardTitle = styled.h2`
  text-transform: uppercase;
  font-weight: bold;
  color: ${theme.colors.primary};
  font-size: 2.4rem;
  position: relative;
  display: inline-block;
  margin-bottom: 1.6rem;

  &:after {
    content: "";
    position: absolute;
    bottom: -0.5rem;
    left: 0;
    right: 0;
    height: 2px;
    background-color: ${theme.colors.primary};
  }
`;

const CardText = styled.p``;

const CardButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.primary};
  border-radius: 100rem;
  border: none;
  padding: 0.4rem 1.6rem;
  color: ${theme.colors.white};
  text-transform: uppercase;
  font-weight: bold;
  margin: 0;
`;

const TextCard = ({ title, content, to }) => {
  return (
    <CardContainer>
      <CardTitle>{title}</CardTitle>
      <CardText>{content}</CardText>
      <FlexCenter>
        {to && (
          <CardButton onClick={() => history.push(to)}>
            Zobacz więcej
          </CardButton>
        )}
      </FlexCenter>
    </CardContainer>
  );
};

export default TextCard;
