import Shop from "./containers/Shop/Shop";
import Service from "./containers/Service/Service";
import Gallery from "./containers/Gallery/Gallery";
import Contact from "./containers/Contact/Contact";
import MainPage from "./containers/MainPage/MainPage";

export const paths = {
  mainPage: "/",
};

export const enPaths = {
  shop: "/shop",
  service: "/service",
  gallery: "/gallery",
  contact: "/contact",
};

export const plPaths = {
  shop: "/sklep",
  service: "/serwis",
  gallery: "/galeria",
  contact: "/kontakt",
};

export const links = [
  {
    path: paths.mainPage,
    name: "Audio-system",
    activePaths: [paths.mainPage],
  },
  {
    path: plPaths.shop,
    name: "Sklep",
    activePaths: [enPaths.shop, plPaths.shop],
  },
  {
    path: plPaths.service,
    name: "Serwis",
    activePaths: [enPaths.service, plPaths.service],
  },
  {
    path: plPaths.gallery,
    name: "Galeria",
    activePaths: [enPaths.gallery, plPaths.gallery],
  },
  {
    path: plPaths.contact,
    name: "Kontakt",
    activePaths: [enPaths.contact, plPaths.contact],
  },
];

export const routes = [
  {
    path: [enPaths.shop, plPaths.shop],
    component: Shop,
  },
  {
    path: [enPaths.service, plPaths.service],
    component: Service,
  },
  {
    path: [enPaths.gallery, plPaths.gallery],
    component: Gallery,
  },
  {
    path: [enPaths.contact, plPaths.contact],
    component: Contact,
  },
  {
    path: "/",
    component: MainPage,
  },
];
