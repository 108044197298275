import React from "react";
import { GoogleMap, LoadScript } from "@react-google-maps/api";

const containerStyle = {
  height: "300px",
  width: "100%",
};

const center = {
  lat: 50.8190205,
  lng: 19.0633589,
};

function MyComponent() {
  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={15} />
    </LoadScript>
  );
}

export default React.memo(MyComponent);
