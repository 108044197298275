import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../theme";
import Map from "../containers/Contact/components/Map";
import { locations, useIsLocation } from "../hooks/useIsLocation";
import axios from "axios";

const BrandsContainer = styled.div`
  gap: 1.6rem;
  padding: 1.6rem;
  background-color: ${theme.colors.lightGray};
  overflow-x: auto;
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  z-index: 1090;

  &:before,
  &:after {
    content: "";
    margin: auto;
  }
`;

const BrandImg = styled.img`
  height: 1.6rem;
`;

const Container = styled.footer`
  padding: 1.6rem;
  background-color: ${theme.colors.dark};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  z-index: 1090;
`;

const FooterText = styled.span`
  color: ${theme.colors.lightDark};
`;

const FooterLink = styled.a`
  all: unset;
  align-items: center;
  cursor: pointer;
  color: ${theme.colors.primary};
  margin: 0 0.8rem;
  text-decoration: underline;
  text-decoration-color: transparent;
  white-space: nowrap;

  &:hover {
    color: ${theme.colors.primary};
    text-decoration-color: ${theme.colors.primary};
    transition: all 250ms ease-out;
  }
`;

const Footer = ({ location }) => {
  const [brands, setBrands] = useState();
  const showMap = useIsLocation(location, locations.map);

  useEffect(() => {
    let url = `${process.env.REACT_APP_API_URL}/brands`;
    return axios
      .get(url)
      .then((response) => {
        return setBrands(response.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      {showMap && (
        <div>
          <Map />
        </div>
      )}
      {brands && (
        <BrandsContainer>
          {brands.map((brand, index) => (
            <BrandImg key={index} src={`${brand.image_url}`} />
          ))}
        </BrandsContainer>
      )}
      <Container>
        <FooterText>
          &copy; {new Date().getFullYear()}
          <FooterLink href={"http://audio-system.com.pl"}>
            AUDIO-SYSTEM
          </FooterLink>
        </FooterText>
        <FooterText>
          Wykonanie:
          <FooterLink href={"mailto:krystiansledzdev@gmail.com"}>
            Krystian Śledź
          </FooterLink>
        </FooterText>
      </Container>
    </>
  );
};

export default withRouter(Footer);
