import React, { useEffect, useState } from "react";
import Aside from "./components/Aside";
import { withRouter } from "react-router-dom";
import ProductList from "./components/ProductList";
import _ from "lodash";
import { history } from "../../helpers/history";
import { getFilters } from "./filters";
import { getCategories, getProducts, getProductsCount } from "./actions";
import styled from "styled-components";

import { device } from "../../theme";

const Container = styled.div`
  padding: 0 1.6rem;
  margin-left: 4.6rem;

  @media all and ${device.laptop} {
    margin-left: 22.7rem;
  }
`;

const Shop = (props) => {
  const { location } = props;
  const [categories, setCategories] = useState();
  const [products, setProducts] = useState();
  const [productsCount, setProductsCount] = useState();
  const [pageCount, setPageCount] = useState();
  const [filters, setFilters] = useState({});

  useEffect(() => {
    getCategories().then((data) => setCategories(data));
  }, []);

  useEffect(() => {
    if (
      !_.isEmpty(filters) &&
      filters.category !== undefined &&
      filters.page &&
      filters.pageLimit
    ) {
      getProducts(filters).then((data) => setProducts(data));
    }
  }, [filters]);

  useEffect(() => {
    if (categories) {
      const newFilters = getFilters(location, categories);
      if (!_.isEqual(filters, newFilters)) {
        if (!_.isEqual(filters.category, newFilters.category))
          getProductsCount(newFilters).then((data) => setProductsCount(data));
        setFilters(newFilters);
      }
    }
  }, [location.search, categories, products]);

  useEffect(() => {
    if (productsCount !== undefined)
      setPageCount(productsCount / filters.pageLimit);
  }, [productsCount]);

  const handlePageClick = (data) => {
    history.push({
      pathname: location.pathname,
      search: location.search.replace(
        `page=${filters.page}`,
        `page=${data.selected + 1}`
      ),
    });
  };

  return (
    <Container>
      {categories && (
        <Aside categories={categories} activeCategory={filters.category} />
      )}
      {products && (
        <ProductList
          products={products}
          pageCount={pageCount}
          handlePageClick={handlePageClick}
          activePage={filters.page}
          activeCategory={filters.category}
          productsCount={productsCount}
        />
      )}
    </Container>
  );
};

export default withRouter(Shop);
