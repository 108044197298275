import React, { useEffect, useState } from "react";
import axios from "axios";

export const TextsContext = React.createContext();

export const TextsKeysConstants = {
  ABOUT_COMPANY: "about_company",
  OFFER: "offer",
  SERVICE: "service",
};

export function useTexts() {
  const [texts, setTexts] = useState();

  useEffect(() => {
    let url = `${process.env.REACT_APP_API_URL}/texts`;
    return axios
      .get(url)
      .then((response) => {
        return setTexts(response.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return texts;
}
