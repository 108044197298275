import React from "react";
import styled from "styled-components";
import mainPageImage from "../assets/Images/main-page.jpg";
import serviceImage from "../assets/Images/service.jpg";
import { device } from "../theme";

const StyledImg = styled.img`
  display: none;
  position: relative;
  width: 100vw;
  height: 60vh;
  object-fit: cover;
  object-position: center;
  margin: -3.2rem -1.6rem 3.2rem -1.6rem;

  @media all and ${device.tablet} {
    display: block;
  }
`;

const Image = ({ imgKey }) => {
  const getImg = (imgKey) => {
    if (imgKey === "mainPage") return mainPageImage;
    if (imgKey === "service") return serviceImage;
  };
  return <StyledImg src={getImg(imgKey)} />;
};

export default Image;
